import * as React from 'react';

import { useState } from 'react';
import { Button, Form, Input, Row, Col } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import FormItem from 'antd/lib/form/FormItem';

const Login = ({ setToken, makeRequest }) => {

    const [ld, setLd] = useState(false);

    const onFinish = (values) => {
        setLd(true);
        makeRequest('POST', `${process.env.REACT_APP_API_URL}/modp/r/login`, values, setToken, setLd)
    };

    return (
        <>
            <Row
                justify="center"
                align="middle"
                style={{
                    padding: "16px 0",
                    minHeight: "65dvh",
                }}
            >
                <Col xs={20} sm={16} md={12} lg={8} xl={4}>
                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                    >
                        <FormItem>
                            <h2>需要登录</h2>
                        </FormItem>
                        <Form.Item
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入用户名！',
                                },
                            ]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="用户名" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入密码！',
                                },
                            ]}
                        >
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="密码"
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary"
                                htmlType="submit"
                                style={{ width: '100%' }}
                                loading={ld}
                                disabled={ld}
                            >
                                登录
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </>
    );
};

export default Login;