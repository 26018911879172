import * as React from 'react';

import { useState, useEffect, useCallback } from 'react';
import { Form, Row, Col, Button, Input, Spin, Switch } from 'antd';
import { useParams } from "react-router-dom";
import {Use404} from "./Use"
import { useSharedValues } from './SharedValueContext';

export default function Device() {
    const { app, did } = useParams();
    const [form] = Form.useForm();
    const [id, setId] = useState(0);
    const [ld, setLd] = useState(false);
    const p404 = Use404();

    const { makeRequest } = useSharedValues()

    const query = useCallback((device_id) => {
        setLd(true);
        makeRequest('GET', `${process.env.REACT_APP_API_URL}/modp/r/${app}/devices/${device_id}`, null, form.setFieldsValue, setLd)
    }, [app, makeRequest, form.setFieldsValue])

    useEffect(() => {
        document.title = `Device`;
        if (!p404 && did) {
            setId(did); query(did);
        }
    }, [p404, did, query]);

    if (p404) {
        return p404
    }

    const okfn2 = (data) => {
        setId(data.id);
        window.history.replaceState({}, "", `/modp/devices/${data.id}`);
    }
    const onFinish = (values) => {
        setLd(true);
        if (id === 0) {
            makeRequest('PUT', `${process.env.REACT_APP_API_URL}/modp/r/${app}/devices`, values, okfn2, setLd)
        } else {
            makeRequest('POST', `${process.env.REACT_APP_API_URL}/modp/r/${app}/devices/${id}`, values, okfn2, setLd)
        }
    };

    return (
        <>
            <Spin spinning={ld}>
                <Row>
                    <Col xs={24} xl={6}>
                        <Form
                            layout={"vertical"}
                            form={form}
                            onFinish={onFinish}
                            autoComplete="off"
                        >
                            <Form.Item>
                                <h2>{id > 0 ? '修改' : '添加'}设备</h2>
                            </Form.Item>
                            <Form.Item
                                label="设备名称"
                                name="device_name"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入设备名称！',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="设备ID"
                                name="device_id"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入设备ID！',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="测试设备"
                                name="beta"
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>

                            <Form.Item>
                                <Row justify="space-between" align="bottom">
                                    <Col>
                                        <Button type="primary" htmlType="submit">
                                            保存
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button htmlType="button" onClick={() => window.history.back()}>
                                            返回
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Spin>
        </>
    );
}
