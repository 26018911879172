import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { Form, Row, Col, Button, Table, Modal, Progress, Popconfirm, Input, Radio, DatePicker } from 'antd';
import { useParams } from "react-router-dom";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Use404 } from './Use';
import { useSharedValues } from './SharedValueContext';
import dayjs from 'dayjs';

// response.blob().then(blob => {
//     var hiddenElement = document.createElement('a');
//     hiddenElement.href = URL.createObjectURL(blob);
//     hiddenElement.target = '_blank';
//     hiddenElement.download = response.headers.get("Content-Disposition").split("filename=")[1];
//     hiddenElement.click();
// })

const { TextArea } = Input;
const { RangePicker } = DatePicker;

const SearchForm = ({ queryList, createVer, open, setOpen }) => {
    const [formSearch] = Form.useForm();
    const [formAdd] = Form.useForm();

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < dayjs().endOf('day');
    };

    return (<>
        <Modal
            open={open}
            okText="保存"
            cancelText="取消"
            onCancel={() => {
                setOpen(false);
            }}
            onOk={() => formAdd.submit()}
        >
            <Form
                form={formAdd}
                layout="vertical"
                onFinish={createVer}
            >
                <Form.Item
                    name="stages"
                    label="阶段"
                    rules={[
                        {
                            required: true,
                            message: '请输入阶段',
                        },
                    ]}
                >
                    <TextArea />
                </Form.Item>

                <Form.Item
                    name="rewards"
                    label="奖励"
                    rules={[
                        {
                            required: true,
                            message: '请输入奖励内容',
                        },
                    ]}>
                    <TextArea />
                </Form.Item>

                <Form.Item
                    name="range"
                    label="活动时间"
                    rules={[
                        {
                            required: true,
                            message: '请选择计划时间',
                        },
                    ]}>
                    <RangePicker
                        disabledDate={disabledDate}
                        style={{ width: '100%' }}
                        showTime={{
                            defaultValue: dayjs('00:00:00', 'HH:mm:ss'),
                        }}
                        format="YYYY-MM-DD HH:mm:ss"
                    />
                </Form.Item>

            </Form>
        </Modal>
        <Form
            form={formSearch}
            initialValues={{}}
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            onFinish={queryList}
        >
            <Row>
                <Col xs={24} xl={16} >
                    <div style={{ textAlign: 'left' }}>
                        <p>W：待开始，C：已取消，√：进行中，L：历史</p>
                        <p>时间是计算机本地时间（注意时区），可简单的添加、取消、启用计划</p>
                        <p>修改计划还是我来吧，正在进行的和5分钟内将要开始的计划暂无法（不重启）修改，计划修改后1-2分钟生效</p>
                    </div>
                </Col>
                <Col xs={24} xl={8} style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={() => {
                        formAdd.setFieldsValue({
                            stages: '[100, 200, 300]',
                            rewards: '[8401, 8402, 8403]',
                        })
                        setOpen(true)
                    }}>
                        添加计划
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col >
                    <br></br>
                </Col>
            </Row>
        </Form>

    </>);
};

export default function Release() {

    let { app } = useParams();
    const [ds, setDs] = useState([]);
    const [ld, setLd] = useState(false);
    const [open, setOpen] = useState(false);
    const [cpt, setCpt] = useState('');

    const { makeRequest } = useSharedValues()
    const p404 = Use404();

    const updateRow = (id, status) => {
        const updatedDataSource = [...ds]; // 创建一个新的数据源副本  
        const index = updatedDataSource.findIndex(item => item.id === id);
        if (index !== -1) {
            updatedDataSource[index] = { ...updatedDataSource[index], release_status: status }; // 更新找到的行数据  
        }
        setDs(updatedDataSource); // 设置更新后的数据源到状态变量  
    };

    const queryList = useCallback(() => {
        setLd(true);
        makeRequest('GET', `${process.env.REACT_APP_API_URL}/modp/r/${app}/treasure`, null, setDs, setLd)
    }, [app, makeRequest])

    useEffect(() => {
        if (!p404) {
            document.title = `Treasure`;
            queryList({});
        }
    }, [p404, queryList]);

    if (p404) {
        return p404;
    }

    const okfn = () => {
        setOpen(false);
        queryList({})
    }

    const createVer = (values) => {
        setLd(true);
        makeRequest('PUT', `${process.env.REACT_APP_API_URL}/modp/r/${app}/treasure`, values, okfn, setLd);
    };

    const togglePlan = (id) => {
        setLd(true);
        makeRequest('POST', `${process.env.REACT_APP_API_URL}/modp/r/${app}/treasure/${id}`, null, okfn, setLd)
    }

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            width: 72,
        },
        {
            title: '阶段',
            dataIndex: 'stages',
            width: 144,
        },
        {
            title: '奖励',
            dataIndex: 'rewards',
            width: 152,
        },
        {
            title: '开始时间',
            dataIndex: 'start_time',
            width: 128,
        },
        {
            title: '结束时间',
            dataIndex: 'end_time',
            width: 128,
        },
        {
            title: '操作',
            width: 176,
            render: (_, r) => {
                let row = null
                switch (r.status) {
                    case 0: //未开始
                        row = <Row gutter={16} justify="start">
                            <Col>
                                <Progress type="circle" size={30} percent={100} format={() => 'W'} />
                            </Col>
                            <Col>
                                <Popconfirm title="确定要取消此计划吗？" okText="确定" cancelText="取消"
                                    onConfirm={() => togglePlan(r.id)}
                                >

                                    <Button type="dashed">
                                        取消
                                    </Button>
                                </Popconfirm>
                            </Col>
                        </Row>
                        break
                    case 1: //发布中
                        row = <Row gutter={16} justify="start">
                            <Col>
                                <Progress type="circle" size={30} percent={100} />
                            </Col>
                        </Row>
                        break
                    case "2": //历史发布
                        row = <Row gutter={16} justify="start">
                            <Col>
                                <Progress type="circle" size={30} percent={100} format={() => 'L'} />
                            </Col>
                        </Row>
                        break
                    case 3: //已取消
                        row = <Row gutter={16} justify="start">
                            <Col>
                                <Progress type="circle" size={30} percent={100} format={() => 'C'} />
                            </Col>
                            <Col>
                                <Popconfirm title="确定启用此计划吗？" okText="确定" cancelText="取消"
                                    onConfirm={() => togglePlan(r.id)}
                                >

                                    <Button type="dashed">
                                        启用
                                    </Button>
                                </Popconfirm>
                            </Col>
                        </Row>
                        break
                    default:
                        row = <Row gutter={16} justify="start">
                            <Col>
                                <Progress type="circle" size={30} percent={100} format={() => r.release_status} />
                            </Col>
                        </Row>
                }
                return row;
            },
        },
    ]

    return (
        <>
            <SearchForm
                queryList={queryList} createVer={createVer} open={open} setOpen={setOpen} />
            <Table
                locale={{ emptyText: '暂无数据' }}
                columns={columns}
                dataSource={ds}
                rowKey={(r) => r.id}
                loading={ld}
                scroll={{ y: '60vh' }}
                pagination={false}
                bordered={true}
            />
        </>
    );
}
