import * as React from 'react';

import { useState, useEffect } from 'react';
import { Form, Button, Switch, Input, Select, Row, Radio, Col, Spin } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
  useNavigate,
  useParams
} from "react-router-dom";
import { useSharedValues } from './SharedValueContext';

const { Option } = Select;

export default function View() {
  let [spinning, setSpinning] = useState(false)
  let { id } = useParams();
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const { makeRequest } = useSharedValues()

  useEffect(() => {
    document.title = `View`;
    setSpinning(true);
    makeRequest('PUT', `${process.env.REACT_APP_API_URL}/modp/r/${id}`, {}, null, setSpinning);
  }, [id, form]);

  const onFinish = (values) => {
    setSpinning(true);
    makeRequest('PUT', `${process.env.REACT_APP_API_URL}/modp/r/${id}`, values, null, setSpinning);
  };

  return <Spin spinning={spinning}>
    <Row gutter={24}>
      <Col span={10}>
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          name="advanced_search"
          onFinish={onFinish}
        >
          <Form.Item label="Name" name="name">
            <Input disabled />
          </Form.Item>
          <Form.Item name="countries" label="country">
            <Select
              mode="tags"
              allowClear
              placeholder="Please select"
              open={false}
            >
            </Select>
          </Form.Item>
          <Form.Item name="onlyBycc" label="bycc" valuePropName="checked">
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Form.Item>
          <Form.Item label="force" name="orgForce">
            <Radio.Group buttonStyle="solid">
              <Radio.Button value={-1} >NO</Radio.Button>
              <Radio.Button value={0} >NOT_ORG</Radio.Button>
              <Radio.Button value={1} >ORG</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="version" name="versionAudit">
            <Input />
          </Form.Item>
          <Form.Item label="status" name="status">
            <Select>
              <Option value={2}>On</Option>
              <Option value={4}>Audit</Option>
            </Select>
          </Form.Item>
          <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button style={{ marginLeft: 8 }} onClick={() => navigate(-1)}>
              Back
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  </Spin>
}



