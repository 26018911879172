import { useEffect } from 'react';
import { Use404 } from './Use';

export default function AppHome() {
  const p404 = Use404();
  useEffect(() => {
    if (!p404) {
      document.title = `AppHome`;
    }
  }, [p404]);
  return p404
}
