import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { Form, Row, Col, Button, Input, Table, Modal, Progress, Popconfirm, Tooltip, Radio, Space, DatePicker, Spin } from 'antd';
import { useParams } from "react-router-dom";
import { SyncOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { Use404 } from './Use';
import { useSharedValues } from './SharedValueContext';

const { TextArea } = Input;

// response.blob().then(blob => {
//     var hiddenElement = document.createElement('a');
//     hiddenElement.href = URL.createObjectURL(blob);
//     hiddenElement.target = '_blank';
//     hiddenElement.download = response.headers.get("Content-Disposition").split("filename=")[1];
//     hiddenElement.click();
// })

const SearchForm = ({ queryList, createVer, open, setOpen }) => {
    const [formSearch] = Form.useForm();
    const [formAdd] = Form.useForm();

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < dayjs().endOf('day');
    };

    return (<>
        <Modal
            open={open}
            okText="保存"
            cancelText="取消"
            onCancel={() => {
                setOpen(false);
            }}
            onOk={() => formAdd.submit()}
        >
            <Form
                form={formAdd}
                layout="vertical"
                onFinish={createVer}
            >
                <Form.Item
                    name="app_version"
                    label="版本"
                    rules={[
                        {
                            required: true,
                            message: '请输入版本',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item name="doc" label="内容" >
                    <TextArea rows={6} />
                </Form.Item>

                <Form.Item
                    name="cdn"
                    label="CDN"
                    rules={[
                        {
                            required: true,
                            message: '请选择CDN',
                        },
                    ]}
                >
                    <Radio.Group buttonStyle="solid">
                        <Radio.Button value="aliyun">aliyun</Radio.Button>
                        <Radio.Button value="cfr2">cloudflare</Radio.Button>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="estimated_time"
                    label="定时发布"
                >
                    <DatePicker
                        rules={[
                            {
                                required: true,
                                message: '请选择计划时间',
                            },
                        ]}
                        format="YYYY-MM-DD HH:mm:ss"
                        disabledDate={disabledDate}
                        showTime={{
                            defaultValue: dayjs('00:00:00', 'HH:mm:ss'),
                        }}
                    />
                </Form.Item>

            </Form>
        </Modal>
        <Form
            form={formSearch}
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            onFinish={queryList}
        >
            <Row gutter={24}>
                <Col xs={14} sm={4} md={6} lg={8} xl={6}>
                    <Form.Item name="app_version">
                        <Input placeholder="版本" />
                    </Form.Item>
                </Col>
                <Col xs={10} sm={4} md={6} lg={8} xl={2}>
                    <Form.Item name="release_status">
                        <Radio.Group>
                            <Radio value="U">使用中</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col xs={6} sm={4} md={6} lg={8} xl={2}>
                    <Button type="primary" htmlType="submit">搜索</Button>
                </Col>
                <Col xs={6} sm={4} md={6} lg={8} xl={2}>
                    <Button
                        onClick={() => {
                            formSearch.resetFields();
                        }}
                    >
                        清空
                    </Button>
                </Col>

                <Col xs={6} sm={4} md={6} lg={8} xl={12} style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={() => {
                        formAdd.setFieldsValue({
                            app_version: '',
                            cdn: '',
                            estimated_time: null,
                            doc: JSON.stringify(JSON.parse('{"Beta":null, "BetaRevision":null, "Ga":null, "GaRevision":null}'), null, 4)
                        })
                        setOpen(true)
                    }}>
                        添加版本
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col >
                    版本单调递增，W：新建未发布， √：正在使用， L：历史发布， N：历史未发布，C：定时发布，NC：定时发布取消，O：处理中，OE：处理失败
                </Col>
            </Row>
        </Form>

    </>);
};

export default function ResRelease() {

    let { app } = useParams();
    const [ds, setDs] = useState([])
    const [ld, setLd] = useState(false)
    const [open, setOpen] = useState(false);
    const [cpt, setCpt] = useState('');
    const p404 = Use404();

    const { token, makeRequest } = useSharedValues()

    const [o, setO] = useState(0);

    const updateRow = (id, status) => {
        const updatedDataSource = [...ds]; // 创建一个新的数据源副本  
        const index = updatedDataSource.findIndex(item => item.id === id);
        if (index !== -1) {
            updatedDataSource[index] = { ...updatedDataSource[index], release_status: status }; // 更新找到的行数据  
        }
        setDs(updatedDataSource); // 设置更新后的数据源到状态变量  
    };

    const queryList = useCallback((values) => {
        setLd(true);
        makeRequest('POST', `${process.env.REACT_APP_API_URL}/modp/r/${app}/rrelease`, values, setDs, setLd)
    }, [app, makeRequest])

    useEffect(() => {
        if (!p404) {
            document.title = `ResRelease`;
            queryList({});
        }
    }, [p404, queryList]);

    useEffect(
        () => {
            if (o !== 0) {
                // 创建EventSource对象，指定服务器端的事件源URL  
                var eventSource = new EventSource(`${process.env.REACT_APP_API_URL}/modp/r/${app}/rrelease/sse/${o}?Auth=${token}`);

                // 连接成功处理  
                eventSource.onopen = function () {
                    console.log('Connected');
                };

                // 接收到事件处理  
                eventSource.onmessage = function (event) {
                    let message = event.data;

                    console.log('Received message: ' + message);
                    if (message !== 'O') {
                        eventSource.close();
                        setO(0);
                        queryList({});
                    }
                };

                // 连接断开处理  
                eventSource.onerror = function () {
                    console.log('Disconnected');
                    eventSource.close();
                };
            }
        }, [o]
    )

    if (p404) {
        return p404;
    }

    const okfn = () => {
        setOpen(false)
        queryList({})
    }

    const createVer = (values) => {
        setLd(true);
        makeRequest('PUT', `${process.env.REACT_APP_API_URL}/modp/r/${app}/rrelease`, values, okfn, setLd);
    };

    const delfn = (id) => {
        setLd(true);
        makeRequest('DELETE', `${process.env.REACT_APP_API_URL}/modp/r/${app}/rrelease/${id}`, null, okfn, setLd)
    }
    const pubfn = (r) => {
        setLd(true);
        makeRequest('POST', `${process.env.REACT_APP_API_URL}/modp/r/${app}/rrelease/${r.id}`, r, okfn, setLd)
    }
    const redofn = (r) => {
        setLd(true);
        makeRequest('PUT', `${process.env.REACT_APP_API_URL}/modp/r/${app}/rrelease/redo/${r.id}`, r, okfn, setLd)
    }
    const cancelfun = (r) => {
        setLd(true);
        makeRequest('POST', `${process.env.REACT_APP_API_URL}/modp/r/${app}/rrelease/cancel/${r.id}`, r, okfn, setLd)
    }

    const view = (doc_revised) => {
        var newWindow = window.open('', '_blank', 'popup');
        var newWindowDocument = newWindow.document;
        newWindowDocument.write('<pre>' + JSON.stringify(JSON.parse(doc_revised), null, 4) + '</pre>');
    }

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            width: 64,
        },
        {
            title: '版本',
            width: 72,
            dataIndex: 'app_version',
        },
        {
            title: 'CDN',
            width: 72,
            dataIndex: 'cdn',
        },
        {
            title: '更新内容',
            dataIndex: 'doc',
            width: 196,
            render: (_, r) => {
                let d = [];
                let doc = JSON.parse(r.doc);
                for (var key in doc) {
                    if (key.endsWith('Revision')) {
                        d.push(key + "->" + doc[key])
                    }
                }
                return d.join(", ")
            }
        },
        {
            title: '创建时间',
            width: 128,
            dataIndex: 'create_time',
        },
        {
            title: '发布时间',
            width: 128,
            render: (_, r) => {
                if (r.release_status === "C" && r.estimated_time) {
                    return <Space direction="vertical" style={{ textAlign: 'left' }}>
                        <span>🕔</span>
                        <span>{r.estimated_time}</span>
                    </Space>;
                } else if (r.release_time) {
                    return r.release_time
                }
            }
        },
        {
            title: '操作',
            width: 296,
            render: (_, r) => {
                let row = null
                switch (r.release_status) {
                    case 'W': //新建
                        row = <Row gutter={8} justify="start">
                            <Col>
                                <Progress type="circle" size={30} percent={100} format={() => 'W'} />
                            </Col>
                            <Col>
                                <Button type="dashed" onClick={() => view(r.doc_revised)}>
                                    详细
                                </Button>
                            </Col>
                            <Col>
                                <Popconfirm title="确定要发布此版本吗？" okText="确定" cancelText="取消"
                                    onConfirm={() => pubfn(r)}
                                >
                                    <Button type="dashed">
                                        发布
                                    </Button>
                                </Popconfirm>
                            </Col>
                            <Col>
                                <Tooltip title={cpt} onMouseEnter={() => setCpt('单击复制')}>
                                    <Button type="dashed" onClick={() => {
                                        setCpt('已复制');
                                        navigator.clipboard.writeText(r.doc_revised);
                                    }}>
                                        复制
                                    </Button>
                                </Tooltip>
                            </Col>
                            <Col>
                                <Popconfirm title="确定要删除此版本吗？" okText="确定" cancelText="取消"
                                    onConfirm={() => delfn(r.id)}
                                >

                                    <Button type="dashed">
                                        删除
                                    </Button>
                                </Popconfirm>
                            </Col>

                        </Row>
                        break
                    case 'U': //发布中
                        row = <Row gutter={8} justify="start">
                            <Col>
                                <Progress type="circle" size={30} percent={100} />
                            </Col>
                            <Col>
                                <Button type="dashed" onClick={() => view(r.doc_revised)}>
                                    详细
                                </Button>
                            </Col>
                            <Col>
                                <Tooltip title={cpt} onMouseEnter={() => setCpt('单击复制')}>
                                    <Button type="dashed" onClick={() => {
                                        setCpt('已复制');
                                        navigator.clipboard.writeText(r.doc_revised);
                                    }}>
                                        复制
                                    </Button>
                                </Tooltip>
                            </Col>
                        </Row>
                        break
                    case 'C': //定时发布
                        row = <Row gutter={8} justify="start">
                            <Col>
                                <Progress type="circle" size={30} percent={100} format={() => 'C'} />
                            </Col>
                            <Col>
                                <Popconfirm title="确定要取消吗？" okText="确定" cancelText="取消"
                                    onConfirm={() => cancelfun(r)}
                                >
                                    <Button type="dashed">
                                        取消
                                    </Button>
                                </Popconfirm>
                            </Col>
                        </Row>
                        break
                    case 'O': //历史发布
                        if (o === 0) {
                            setO(r.id);
                        }
                        row = <Row gutter={8} justify="start">
                            <Spin size='large'
                                indicator={
                                    <SyncOutlined spin />
                                }
                            />
                        </Row>
                        break
                    case 'OE': //历史发布
                        row = <Row gutter={8} justify="start">
                            <Col>
                                <Progress type="circle" size={30} percent={100} format={() => 'OE'} />
                            </Col>
                            <Col>
                                <Popconfirm title="确定要吗？" okText="确定" cancelText="取消"
                                    onConfirm={() => redofn(r)}
                                >
                                    <Button type="dashed">
                                        redo
                                    </Button>
                                </Popconfirm>
                            </Col>
                        </Row>
                        break
                    case 'L': //历史发布
                        row = <Row gutter={8} justify="start">
                            <Col>
                                <Progress type="circle" size={30} percent={100} format={() => 'L'} />
                            </Col>
                            <Col>
                                <Button type="dashed" onClick={() => view(r.doc_revised)}>
                                    详细
                                </Button>
                                <Tooltip title={cpt} onMouseEnter={() => setCpt('单击复制')}>
                                    <Button type="dashed" onClick={() => {
                                        setCpt('已复制');
                                        navigator.clipboard.writeText(r.doc_revised);
                                    }}>
                                        复制
                                    </Button>
                                </Tooltip>
                            </Col>
                        </Row>
                        break
                    default:
                        row = <Row gutter={8} justify="start">
                            <Col>
                                <Progress type="circle" size={30} percent={100} format={() => r.release_status} />
                            </Col>
                            <Col>
                                {/* <Button type="dashed" onClick={() => viewfn(r)}>
                                    详细
                                </Button> */}
                            </Col>
                        </Row>
                }
                return row;
            },
        },
    ]

    return (
        <>
            <SearchForm
                queryList={queryList} createVer={createVer} open={open} setOpen={setOpen} />
            <Table
                locale={{ emptyText: '暂无数据' }}
                columns={columns}
                dataSource={ds}
                rowKey={(r) => r.id}
                loading={ld}
                scroll={{ y: '60vh' }}
                pagination={false}
                bordered={true}
                expandable={{
                    expandedRowRender: (record) => {
                        let output = JSON.parse(record.output)
                        return <pre
                            style={{
                                margin: 0,
                            }}
                        >
                            {output.d}
                        </pre>
                    },
                    rowExpandable: (record) => record.status !== 'Not Expandable',
                }}
            />
        </>
    );
}
