import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { Form, Row, Col, Button, Table, Modal, Progress, Popconfirm, Tooltip, Switch, InputNumber, Space } from 'antd';
import { useParams } from "react-router-dom";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Use404 } from './Use';
import { useSharedValues } from './SharedValueContext';

// response.blob().then(blob => {
//     var hiddenElement = document.createElement('a');
//     hiddenElement.href = URL.createObjectURL(blob);
//     hiddenElement.target = '_blank';
//     hiddenElement.download = response.headers.get("Content-Disposition").split("filename=")[1];
//     hiddenElement.click();
// })

const SearchForm = ({ queryList, createVer, open, setOpen }) => {
    const [formSearch] = Form.useForm();
    const [formAdd] = Form.useForm();

    return (<>
        <Modal
            title="类型比例"
            open={open}
            okText="保存"
            cancelText="取消"
            onCancel={() => {
                setOpen(false);
            }}
            onOk={() => {
                if (formAdd.getFieldValue("types").length > 0) {
                    formAdd.submit()
                }
            }}
        >
            <Form
                form={formAdd}
                layout="vertical"
                onFinish={createVer}
            >
                <Form.List name="types">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Space
                                    key={key}
                                    style={{
                                        display: 'flex',
                                        marginTop: 8,
                                    }}
                                    align="baseline"
                                >
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'N']}
                                        rules={[
                                            {
                                                required: true,
                                                message: '缺少类型值',
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            addonBefore="类型"
                                            placeholder='1-32的整数'
                                            min="1"
                                            max="32"
                                            precision={0}
                                            step="1"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'Prob']}
                                        rules={[
                                            {
                                                required: true,
                                                message: '缺少概率',
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            addonAfter="%"
                                            placeholder='0-100的整数'
                                            min="0"
                                            max="100"
                                            precision={0}
                                            step="1"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'Redo']}
                                    >
                                        <Switch checkedChildren="redo" unCheckedChildren="no" />
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                </Space>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    添加类型
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

            </Form>
        </Modal>
        <Form
            form={formSearch}
            initialValues={{}}
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            onFinish={queryList}
        >
            <Row >
                <Col xs={24} xl={16}>
                    {/* 
                        <Col span={6}>
                            <Form.Item name="app_version">
                                <Input placeholder="版本" />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name="release_status">
                                <Radio.Group>
                                    <Radio value="U">使用中</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Button type="primary" htmlType="submit">搜索</Button>
                        </Col>
                        <Col>
                            <Button
                                onClick={() => {
                                    formSearch.resetFields();
                                }}
                            >
                                清空
                            </Button>
                        </Col>
                     */}
                    <div style={{ textAlign: 'left' }}>
                        <p>版本单调递增，W：新建未发布， √：正在使用， L：历史发布， N：历史未发布</p>
                    </div>

                </Col>
                <Col xs={24} xl={8} style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={() => {
                        formAdd.setFieldsValue({
                            types: []
                        })
                        setOpen(true)
                    }}>
                        添加版本
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col >
                    <br></br>
                </Col>
            </Row>
        </Form>

    </>);
};

export default function Release() {

    let { app } = useParams();
    const [ds, setDs] = useState([]);
    const [ld, setLd] = useState(false);
    const [open, setOpen] = useState(false);
    const [cpt, setCpt] = useState('');

    const { makeRequest } = useSharedValues()
    const p404 = Use404();

    const updateRow = (id, status) => {
        const updatedDataSource = [...ds]; // 创建一个新的数据源副本  
        const index = updatedDataSource.findIndex(item => item.id === id);
        if (index !== -1) {
            updatedDataSource[index] = { ...updatedDataSource[index], release_status: status }; // 更新找到的行数据  
        }
        setDs(updatedDataSource); // 设置更新后的数据源到状态变量  
    };

    const queryList = useCallback((values) => {
        setLd(true);
        makeRequest('POST', `${process.env.REACT_APP_API_URL}/modp/r/${app}/n1release`, values, setDs, setLd)
    }, [app, makeRequest])

    useEffect(() => {
        if (!p404) {
            document.title = `AbRelease`;
            queryList({});
        }
    }, [p404, queryList]);

    if (p404) {
        return p404;
    }

    const okfn = () => {
        setOpen(false);
        queryList({})
    }

    const createVer = (values) => {
        let types = values["types"];
        let doc = {};
        for (const type of types) {
            if (type.Prob > 0) {
                if (type.Redo) {
                    type['Uid'] = crypto.randomUUID();
                } else {
                    type.Redo = false
                }
                doc[type.N.toString()] = type
            } else {
                doc[type.N.toString()] = null
            }
        }
        let body = { "doc": JSON.stringify(doc) };
        setLd(true);
        makeRequest('PUT', `${process.env.REACT_APP_API_URL}/modp/r/${app}/n1release`, body, okfn, setLd);
    };

    const delfn = (id) => {
        setLd(true);
        makeRequest('DELETE', `${process.env.REACT_APP_API_URL}/modp/r/${app}/n1release/${id}`, null, okfn, setLd)
    }
    const pubfn = (r) => {
        setLd(true);
        makeRequest('POST', `${process.env.REACT_APP_API_URL}/modp/r/${app}/n1release/${r.id}`, r, okfn, setLd)
    }

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            width: 65,
        },
        {
            title: '更新内容',
            width: 360,
            dataIndex: 'doc',
            render: (_, r) => {
                let doc = JSON.parse(r.doc);
                const d = [];
                for (var key in doc) {
                    if (doc[key]) {
                        d.push(key + "->" + doc[key]["Prob"] + "% " + (doc[key]["Redo"] ? "redo" : ""))
                    } else {
                        d.push(key + "->null")
                    }

                }
                return d.join(", ")
                for (const item of doc) {
                    d.push(`${item.n}->${item.percentage}`);
                }
                return <Space direction="vertical" style={{ textAlign: 'left' }}>
                    {d.join(", ")}
                </Space>;
            }
        },
        {
            title: '更新后',
            width: 360,
            render: (_, r) => {
                let doc = JSON.parse(r.doc_revised);
                const d = [];
                for (var key in doc) {
                    d.push(key + "->" + doc[key]["Prob"] + "%" + (doc[key]["Redo"] ? " redo" : ""))
                }
                return d.join(", ")
                for (const item of doc) {
                    d.push(`${item.n}->${item.percentage}`);
                }
                return <Space direction="vertical" style={{ textAlign: 'left' }}>
                    {d.join(", ")}
                </Space>;
            }
        },
        {
            title: '创建时间',
            width: 180,
            dataIndex: 'create_time',
        },
        {
            title: '发布时间',
            width: 180,
            render: (_, r) => {
                if (r.release_status === "C" && r.estimated_time) {
                    return <Space direction="vertical" style={{ textAlign: 'left' }}>
                        <span>🕔</span>
                        <span>{r.estimated_time}</span>
                    </Space>;
                } else if (r.release_time) {
                    return r.release_time
                }
            }
        },
        {
            title: '操作',
            width: 224,
            render: (_, r) => {
                let row = null
                switch (r.release_status) {
                    case 'W': //新建
                        row = <Row gutter={8} justify="start">
                            <Col>
                                <Progress type="circle" size={30} percent={100} format={() => 'W'} />
                            </Col>
                            <Col>
                                <Popconfirm title="确定要发布此版本吗？" okText="确定" cancelText="取消"
                                    onConfirm={() => pubfn(r)}
                                >
                                    <Button type="dashed">
                                        发布
                                    </Button>
                                </Popconfirm>
                                <Tooltip title={cpt} onMouseEnter={() => setCpt('单击复制')}>
                                    <Button type="dashed" onClick={() => {
                                        setCpt('已复制');
                                        navigator.clipboard.writeText(r.doc);
                                    }}>
                                        复制
                                    </Button>
                                </Tooltip>
                                <Popconfirm title="确定要删除此版本吗？" okText="确定" cancelText="取消"
                                    onConfirm={() => delfn(r.id)}
                                >

                                    <Button type="dashed">
                                        删除
                                    </Button>
                                </Popconfirm>
                            </Col>
                        </Row>
                        break
                    case 'U': //发布中
                        row = <Row gutter={16} justify="start">
                            <Col>
                                <Progress type="circle" size={30} percent={100} />
                            </Col>
                            <Col>
                                <Tooltip title={cpt} onMouseEnter={() => setCpt('单击复制')}>
                                    <Button type="dashed" onClick={() => {
                                        setCpt('已复制');
                                        navigator.clipboard.writeText(r.doc);
                                    }}>
                                        复制
                                    </Button>
                                </Tooltip>
                            </Col>
                        </Row>
                        break
                    case 'L': //历史发布
                        row = <Row gutter={16} justify="start">
                            <Col>
                                <Progress type="circle" size={30} percent={100} format={() => 'L'} />
                            </Col>
                            <Col>
                                <Tooltip title={cpt} onMouseEnter={() => setCpt('单击复制')}>
                                    <Button type="dashed" onClick={() => {
                                        setCpt('已复制');
                                        navigator.clipboard.writeText(r.doc);
                                    }}>
                                        复制
                                    </Button>
                                </Tooltip>
                            </Col>
                        </Row>
                        break
                    default:
                        row = <Row gutter={16} justify="start">
                            <Col>
                                <Progress type="circle" size={30} percent={100} format={() => r.release_status} />
                            </Col>
                        </Row>
                }
                return row;
            },
        },
    ]

    return (
        <>
            <SearchForm
                queryList={queryList} createVer={createVer} open={open} setOpen={setOpen} />
            <Table
                locale={{ emptyText: '暂无数据' }}
                columns={columns}
                dataSource={ds}
                rowKey={(r) => r.id}
                loading={ld}
                scroll={{ y: '60vh' }}
                pagination={false}
                bordered={true}
            />
        </>
    );
}
