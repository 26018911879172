import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { Form, Row, Col, Button, Input, Table, Modal, Switch } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Link, useParams } from "react-router-dom";
import { Use404 } from "./Use"
import { useSharedValues } from './SharedValueContext';

const SearchForm = ({ app, queryList }) => {
    const [form] = Form.useForm();

    return (
        <Form
            form={form}
            initialValues={{ 'dn': '' }}
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            onFinish={queryList}
        >
            <Row gutter={18}>
                <Col xs={12} sm={4} md={6} lg={8} xl={16}>
                    <Row gutter={18}>
                        <Col xs={16} sm={4} md={6} lg={8} xl={10}>
                            <Form.Item name="dn">
                                <Input placeholder="名称/ID模糊查询" allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={4} sm={4} md={6} lg={8} xl={10}>
                            <Button type="primary" htmlType="submit">搜索</Button>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} sm={4} md={6} lg={8} xl={8} style={{ textAlign: 'right' }}>
                    <Link to={`/modp/${app}/device`}>
                        <Button type="primary">添加设备</Button>
                    </Link>
                </Col>
            </Row>
        </Form>
    );
};

const { confirm } = Modal;

export default function Devices() {

    let { app } = useParams();
    const p404 = Use404();
    const [ds, setDs] = useState([])
    const [ld, setLd] = useState(false)
    const { makeRequest } = useSharedValues()

    const queryList = useCallback((values) => {
        setLd(true);
        makeRequest('GET', `${process.env.REACT_APP_API_URL}/modp/r/${app}/devices?dn=${values['dn']}`, null, setDs, setLd)
    }, [app, makeRequest])

    useEffect(() => {
        if (!p404) {
            document.title = `Devices`;
            queryList({ 'dn': '' })
        }
    }, [p404, queryList]);

    if (p404) {
        return p404
    }

    const delOk = (id) => {
        setDs(ds => ds.filter(item => item.id !== id));
    }
    const showPromiseConfirm = (id) => {
        confirm({
            title: '确定删除这个设备?',
            icon: <ExclamationCircleOutlined />,
            content: '从设备列表删除（玩家数据不会删除）',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                setLd(true)
                makeRequest('DELETE', `${process.env.REACT_APP_API_URL}/modp/r/${app}/devices/${id}`, null, delOk, setLd)
            },
            onCancel() { },
        });
    }

    const betaOk = (changed) => {
        setDs(ds => ds.map(item => item.id === changed.id ? changed : item))
    }
    const beta = (r) => {
        setLd(true)
        makeRequest('POST', `${process.env.REACT_APP_API_URL}/modp/r/${app}/devices/${r.id}`, r, betaOk, setLd)
    };

    const columns = [
        {
            title: '名称',
            dataIndex: 'device_name',
            width: 136,
        },
        {
            title: '设备ID',
            dataIndex: 'device_id',
            width: 136,
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            width: 128,
        },
        {
            title: '操作',
            width: 224,
            render: (_, r) => {
                return <>
                    <Row gutter={8} justify="start">
                        <Col >
                            <Switch checked={r.beta} onChange={b => {
                                r.Beta = b; beta(r)
                            }} />
                        </Col>
                        <Col>
                            <Link to={`/modp/${app}/devices/${r.id}`}>
                                <Button type="dashed">
                                    修改
                                </Button>
                            </Link>
                        </Col>
                        <Col>
                            <Button type="dashed" danger
                                onClick={() => showPromiseConfirm(r.id)}
                            >
                                删除
                            </Button>
                        </Col>
                    </Row>
                </>
            },
        },
    ]

    return (
        <>
            <SearchForm
                app={app}
                queryList={queryList} />
            <Table
                locale={{ emptyText: '暂无数据' }}
                columns={columns}
                dataSource={ds}
                rowKey={(r) => r.id}
                loading={ld}
                scroll={{ y: '56vh' }}
                pagination={false}
                bordered={true}
            />
        </>

    );
}
