import * as React from 'react';

import { useState, useEffect, useCallback } from 'react';
import { Form, Row, Col, Button, Input, Select, Modal, Spin, InputNumber, Tooltip, Space } from 'antd';
import { ExclamationCircleOutlined, InfoCircleOutlined, UpOutlined } from '@ant-design/icons';
import { useParams } from "react-router-dom";

import { Use404 } from "./Use"
import { useSharedValues } from './SharedValueContext';

const { Option } = Select;
const { TextArea } = Input;
const { confirm } = Modal;

export default function Data() {
    const { app } = useParams();
    const [store, setStore] = useState([]);
    const [form] = Form.useForm();
    const [ss, setSs] = useState(null)
    const [ld, setLd] = useState(false)
    const p404 = Use404();
    const { makeRequest } = useSharedValues();
    const n1Value = Form.useWatch('n1', form);

    const [campChanged, setCampChanged] = useState(false);
    const [dataChanged, setDataChanged] = useState(false);
    const [ramChanged, setRamChanged] = useState(false);
    const [deviceId, setDeviceId] = useState('')

    const clearChanged = (ss) => {
        setCampChanged(false);
        setRamChanged(false);
        setDataChanged(false);
        setSs(ss);
    }

    const queryStore = useCallback(() => {
        clearChanged();
        makeRequest('GET', `${process.env.REACT_APP_API_URL}/modp/r/${app}/devices/store`, null, setStore, setLd)
    }, [app, makeRequest])

    useEffect(() => {
        if (!p404) {
            document.title = `Data`;
            queryStore();
        }
    }, [p404, queryStore]);

    if (p404) {
        return p404;
    }

    const okfn = () => {
        clearChanged();
        form.resetFields(["level", "data", "campaign", "help_count", "n1", "modified", "n1_tags"]);
    }
    function showPromiseConfirm() {
        confirm({
            title: '确定删除这个设备的玩家数据?',
            icon: <ExclamationCircleOutlined />,
            content: '请确认是要删除的设备',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                makeRequest('DELETE', `${process.env.REACT_APP_API_URL}/modp/r/${app}/data/${deviceId}`, null, okfn, setLd)
            },
            onCancel() { },
        });
    }

    const okfn3 = (data) => {
        clearChanged(data);
        //format json
        data.data = JSON.stringify(JSON.parse(data.data), null, 4);
        data.n1_tags = getBitPositions(data.n1);
        form.setFieldsValue(data);
    }
    function getData() {
        setLd(true);
        makeRequest('GET', `${process.env.REACT_APP_API_URL}/modp/r/${app}/data/${deviceId}`, null, okfn3, setLd)
    }

    function saveData() {
        setLd(true);
        ss['camp_changed'] = campChanged
        ss['ram_changed'] = ramChanged
        ss['data_changed'] = dataChanged
        makeRequest('POST', `${process.env.REACT_APP_API_URL}/modp/r/${app}/data/${deviceId}`, ss, okfn3, setLd)
    }

    function getBitPositions(number) {
        const bitPositions = [];
        for (var i = 1; i <= 32; i++) {
            if (number & 1) {
                bitPositions.push(i.toString());
            }
            number >>= 1;
        }
        return bitPositions;
    }

    const options = [];
    for (let i = 1; i < 32; i++) {
        options.push({
            value: i.toString(),
            label: i.toString(),
        });
    }
    const handleN1Change = (value) => {
        let n1 = 0;
        for (const element of value) {
            n1 = n1 | (1 << (element - 1));
        }
        form.setFieldValue('n1', n1);
        setSs(pre => ({ ...pre, n1: n1 }));
        setRamChanged(true);
    };

    return (
        <>
            {app === 'market' ? <div style={{ marginBottom: 16 }}>

            </div> : <></>
            }
            <Spin spinning={ld}>
                <Form
                    form={form}
                    layout={"vertical"}
                    name="data_form"
                >
                    <Row gutter={24}>
                        <Col xs={24} sm={4} md={6} lg={8} xl={6}>
                            <Form.Item name="device_id">
                                <Select
                                    showSearch
                                    placeholder="选择要查询的设备"
                                    onChange={v => {
                                        clearChanged();
                                        setDeviceId(v);
                                        form.resetFields(["level", "data", "campaign", "help_count", "n1", "modified", "n1_tags"]);
                                    }}
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                >
                                    {store.map(e => (
                                        <Option value={e.device_id} key={e.device_id}>{e.device_name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={6} sm={4} md={6} lg={8} xl={1}>
                            <Button
                                type="primary"
                                onClick={getData} >
                                搜索
                            </Button>

                        </Col>
                        {(campChanged || ramChanged || dataChanged) && ss ?
                            <Col xs={6} sm={4} md={6} lg={8} xl={1}>
                                <Button
                                    type="dashed"
                                    onClick={saveData}>
                                    保存
                                </Button>
                            </Col> : <></>
                        }

                        {ss ?
                            <>
                                <Col xs={6} sm={4} md={6} lg={8} xl={1}>
                                    <Button
                                        type="dashed"
                                        danger
                                        onClick={showPromiseConfirm}
                                    >
                                        删除
                                    </Button>
                                </Col>
                                <Col xs={2} sm={4} md={6} lg={8} xl={1} style={{
                                    marginTop: 6
                                }}>
                                    <Tooltip overlayStyle={{ maxWidth: '400px' }}
                                        placement="right"
                                        title="删除并不是真正的删除，只是修改你的设备id使无法找回，数据还会在游戏中；数据变化会实时反映到排行榜，谨慎修改并及时恢复到“正常值”">
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                </Col>
                            </>
                            : <></>
                        }
                    </Row>

                    {app === 'tilescapes' || app === 'itilescapes' || app === 'itriplemaster2' || app == "triplemaster2" || app === 'ap1'
                        || app === 'goods5' || app === 'igoods5' ?
                        <Row>
                            <Col xs={24} sm={4} md={6} lg={8} xl={6} >
                                <Form.Item label="来源" name="campaign">
                                    <Input style={{ width: '97%' }} onChange={e => {
                                        setSs(pre => ({ ...pre, campaign: e.target.value }));
                                        setCampChanged(true);
                                    }} />
                                </Form.Item>
                            </Col>
                        </Row> : <></>
                    }

                    <Row>
                        <Col xs={24} sm={4} md={6} lg={8} xl={6} >
                            <Form.Item label="等级" name="level">
                                <InputNumber min={1} style={{ width: '97%' }} onChange={v => {
                                    setSs(pre => ({ ...pre, level: v }));
                                    setRamChanged(true);
                                }} />
                            </Form.Item>
                        </Col>
                    </Row>

                    {app === 'triplemaster2' || app === 'itriplemaster2' || app === 'goods5'
                        || app === 'gsort' || app === 'igsort'
                        || app === 'igoods5' || app === 'ap1' || app === 'hexaup' || app === 'ihexaup'
                        || app === 'hexaupvx' || app === 'hexasort' || app === 'ihexasort' ?
                        <Row>
                            <Col xs={24} sm={4} md={6} lg={8} xl={6}  >
                                <Form.Item label="用户类型值" name="n1">
                                    <Input style={{ width: '97%' }} disabled />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={4} md={6} lg={8} xl={6} >
                                <Form.Item label=" " name="n1_tags">
                                    <Select
                                        mode="tags"
                                        placeholder="Please select"
                                        onChange={handleN1Change}
                                        style={{
                                            width: '100%',
                                        }}
                                        options={options}
                                    />

                                </Form.Item>
                            </Col>
                        </Row> : <></>
                    }

                    {app === 'triplemaster2' || app === 'itriplemaster2' || app === 'goods5'
                        || app === 'gsort' || app === 'igsort'
                        || app === 'igoods5' || app === 'ap1' || app === 'hexaup' || app === 'ihexaup'
                        || app === 'hexaupvx' || app === 'hexasort' || app === 'ihexasort' ?
                        <Row>
                            <Col xs={24} sm={4} md={6} lg={8} xl={6} >
                                <Form.Item label="待同步" name="modified" >
                                    <Input style={{ width: '97%' }} disabled />
                                </Form.Item>
                            </Col>
                        </Row> : <></>
                    }

                    {/* {app === 'market' ?
                        <Row>
                            <Col span={6} >
                                <Form.Item label="帮助" name="help_count">
                                    <InputNumber min={0} style={{ width: '97%' }} onChange={v =>
                                        setSs(pre => ({ ...pre, d: { ...pre.d, help_count: v }}))} />
                                </Form.Item>
                            </Col>
                        </Row> : <></>
                    } */}
                    <Row>
                        <Col span={24} >
                            <Form.Item label="数据" name="data">
                                <TextArea rows={16} onChange={e => {
                                    setSs(pre => ({ ...pre, data: e.target.value }));
                                    setDataChanged(true);
                                }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </>
    );
}
