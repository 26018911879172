import React from 'react';
import { Layout, Menu, Breadcrumb, Result, Button, theme } from 'antd';
import { LaptopOutlined, BranchesOutlined, GroupOutlined, UserOutlined } from '@ant-design/icons';
import { Link, Routes, Route, BrowserRouter as Router, useLocation } from "react-router-dom";
import { useSharedValues } from './SharedValueContext';

import Devices from './Devices';
import Treasures from './Treasures';
import Device from './Device';
import Data from './Data';
import Skad from './Skad';
import Mapps from './Mapps';
import Mapp from './Mapp';
import Release from './Release';
import ResRelease from './ResRelease';
import AbRelease from './AbRelease';
import Acquire from './Acquire';
import Apps from './Apps';
import AppHome from './AppHome';
import { SharedValueProvider } from './SharedValueContext';

const { Header, Content, Footer, Sider } = Layout;

const P404 = () => {
    return <Result
        status="404"
        title="404"
        subTitle="抱歉，这个页面暂时不存在。"
        extra={<Button type="primary"><Link to={`/modp`} >Back Home</Link></Button>}
    />
}

const modpReg = new RegExp(`^/modp(\/.*)?$`);
const ipReg = new RegExp(`^/modp/ip(\/.*)?$`);
const skadReg = new RegExp(`^/modp/skad(\/.*)?$`);

const MenuTop = () => {
    const location = useLocation();
    let keys = [];
    if (modpReg.test(location.pathname)) {
        if (skadReg.test(location.pathname)) {
            keys = ['skad'];
        } else if (ipReg.test(location.pathname)) {
            keys = ['ip']
        } else {
            keys = ['modp'];
        }
    }
    const items = [
        {
            key: "modp",
            label: <Link to={`/modp`} >home</Link>
        }
        , {
            key: `skad`,
            label: <Link to={`/modp/skad`} >skad</Link>,
        }
        , {
            key: `ip`,
            label: <Link to={`/modp/ip`} >ip</Link>,
        }
        // , {
        //     key: `ac`,
        //     label: <Link to={`/modp/ac`} >表现</Link>,
        // }
    ];
    return <Menu
        theme="dark"
        mode="horizontal "
        selectedKeys={keys}
        items={items}
    />
}

const MenuLeft = ({ colorBgContainer }) => {
    const location = useLocation();
    const { apps } = useSharedValues()
    if (!apps) {
        return <></>
    }
    let allowedValues = apps.map(item => item.app);
    const pattern = new RegExp(`^/modp/(${allowedValues.join('|')})(\/.*)?$`);
    if (!pattern.test(location.pathname)) {
        return <></>
    }
    const pathSnippets = location.pathname.split('/').filter((i) => i);
    let app = pathSnippets[1];
    const items = [
        {
            key: `data`,
            icon: React.createElement(LaptopOutlined),
            label: <Link to={`/modp/${app}/data`} >设备数据</Link>,
        }
        , {
            key: `release`,
            icon: React.createElement(BranchesOutlined),
            label: <Link to={`/modp/${app}/release`} >配置版本</Link>,
        }
        , {
            key: `rrelease`,
            icon: React.createElement(GroupOutlined),
            label: <Link to={`/modp/${app}/rrelease`} >资源版本</Link>,
        }
        , {
            key: `abrelease`,
            icon: React.createElement(GroupOutlined),
            label: <Link to={`/modp/${app}/abrelease`} >用户类型</Link>,
        }
        , {
            key: `treasure`,
            icon: React.createElement(GroupOutlined),
            label: <Link to={`/modp/${app}/treasure`} >活动计划</Link>,
        }
        , {
            key: `devices`,
            icon: React.createElement(UserOutlined),
            label: <Link to={`/modp/${app}/devices`} >设备管理</Link>,
        }
    ];
    // if (/^i.*/.test(app)) {
    //     items.push({
    //         key: `skad`,
    //         icon: React.createElement(ScheduleOutlined), 
    //         label: <Link to={`/modp/${app}/skad`} >转化值</Link>,
    //     })
    // }
    let keys = [];
    if (pathSnippets.length >= 3) {
        let i = items.findIndex(item => {
            return item.key === pathSnippets[2]
        })
        if (i >= 0) {
            keys.push(pathSnippets[2]);
        }
    }

    return <Sider style={{
        background: colorBgContainer,
    }}
        breakpoint="lg"
        collapsedWidth="0"
    >
        <Menu
            mode="inline"
            items={items}
            selectedKeys={keys}
        />
    </Sider>
}

const CustomeBreadcrumb = () => {
    const location = useLocation();
    const pathSnippets = location.pathname.split('/').filter((i) => i);
    const extraBreadcrumbItems = pathSnippets.map((_, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
        return { title: <Link to={url}>{pathSnippets[index]}</Link> };
    });
    const breadcrumbItems = [].concat(extraBreadcrumbItems);
    return <Breadcrumb style={{ margin: '16px 0' }} items={breadcrumbItems} />
}

export default function Main() {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <SharedValueProvider>
            <Router>
                <Layout>
                    <Header>
                        <MenuTop />
                    </Header>

                    <Content className='ct-padding'>
                        <CustomeBreadcrumb />
                        <Layout
                            style={{
                                padding: '24px 0',
                                background: colorBgContainer,
                            }}
                        >
                            <MenuLeft colorBgContainer={colorBgContainer} />
                            <Content
                                style={{
                                    padding: '0 24px',
                                    minHeight: '75vh',
                                }}
                            >
                                <Routes>
                                    <Route path={`/modp/`} element={<Apps />} />

                                    <Route path={`/modp/:app/treasure`} element={<Treasures />} />
                                    <Route path={`/modp/:app/devices`} element={<Devices />} />
                                    <Route path={`/modp/:app/devices/:did`} element={<Device />} />
                                    <Route path={`/modp/:app/device`} element={<Device />} />

                                    <Route path={`/modp/mapps`} element={<Mapps />} />
                                    <Route path={`/modp/mapps/:id`} element={<Mapp />} />

                                    <Route path={`/modp/:app/skad`} element={<Skad />} />
                                    <Route path={`/modp/:app/`} element={<AppHome />} />
                                    <Route path={`/modp/:app/data`} element={<Data />} />
                                    <Route path={`/modp/:app/release`} element={<Release />} />
                                    <Route path={`/modp/:app/rrelease`} element={<ResRelease />} />
                                    <Route path={`/modp/:app/abrelease`} element={<AbRelease />} />


                                    <Route path={`/modp/ip`} element={<></>} />
                                    <Route path={`/modp/ac`} element={<Acquire />} />
                                    <Route path={`/modp/skad`} element={<Skad />} />

                                    <Route path="*" element={<P404 />} />
                                </Routes>

                            </Content>
                        </Layout>
                    </Content>
                    <Footer
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        ©2024
                    </Footer>
                </Layout>
            </Router>
        </SharedValueProvider>
    )
};
