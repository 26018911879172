import React from 'react';
import { AppleOutlined, AndroidOutlined } from '@ant-design/icons';
import { Card, Row, Col, Spin } from 'antd';
import { Link } from "react-router-dom";

import { useSharedValues } from './SharedValueContext';

const { Meta } = Card;

export default function Apps() {
    const { apps } = useSharedValues()
    let app_cards = [];
    if (apps && apps.length > 0) {
        for (const app of apps) {
            let actions = [];
            if (app.os === 'ios') {
                actions.push(
                    <AppleOutlined onClick={
                        () => window.open(`https://apps.apple.com/us/app/${app.store_id}`)
                    } />
                )
            } else {
                actions.push(
                    <AndroidOutlined onClick={
                        () => window.open(`https://play.google.com/store/apps/details?id=${app.store_id}`)
                    } />
                )
            }
            app_cards.push(
                <Col xs={20} sm={16} md={12} lg={8} xl={4} key={app.id}>
                    <Card
                        hoverable
                        size='small'
                        actions={actions}
                    >
                        <Link to={`/modp/${app.app}`} >
                            <Row gutter={{
                                xs: 8,
                                sm: 16,
                                md: 24,
                                lg: 32,
                            }}>
                                <Col span={12}>
                                    <img alt={app.app} src={`/modp/icons/mapps/${app.app}.webp`} style={{ width: '100%' }} />

                                </Col>
                                <Col span={12}>
                                    <Meta
                                        title={app.name}
                                        description={app.app}
                                    />
                                </Col>
                            </Row>
                        </Link>
                    </Card>
                </Col>
            )
        }
    }

    return <Row gutter={{
        xs: 8,
        sm: 16,
        md: 24,
        lg: 32,
    }} justify="start" >{app_cards}</Row>

};
